<template>
  <Loader class="mt-6 flex justify-center" v-if="showLoaderComp" />
  <div v-else class="flex flex-col justify-center items-center">
    <!-- <FormError :errors="getError" /> -->

    <div
      class="mt-6 w-full hidden md:block lg:hidden justify-center items-center"
    >
      <img
        class="h-72 m-auto"
        src="@/assets/images/perpetual-logo.svg"
        alt="perpetual-logo"
      />
    </div>
    <!--   <HeaderGlobal class="mt-16" titlemenu1="Friends" :showDeceased="false" /> -->
    <HeaderGlobal titlemenu1="Friends-" titlemenu2="Invitations" />
    <!--  title2="Interest Group" -->

    <div
      class="w-full pl-0 md:px-4 pt-8 pb-8 bg-lightBlue text-left font-proximaMedium rounded-br-2xl"
    >
      <FormError :errors="errorForm" />

      <div v-if="petoverviewTab == 1">
        <h4
          v-if="pendingContacts.length + contacts.length == 0"
          class="mb-6 text-xl"
        >
          <p>Your address book is empty, click the + to add a friend!</p>
        </h4>
        <ContactFriends
          v-if="pendingContacts.length + contacts.length > 0"
          :contacts="contacts"
          :pendingContacts="pendingContacts"
          :deleteConfirmation="deleteConfirmation"
          @deletListFirend="deletListFirend"
        />
      </div>
      <div v-if="petoverviewTab == 2">
        <ContactInvitations
          v-if="pendingContacts.length + contacts.length > 0"
          :contacts="contacts"
          :pendingContacts="pendingContacts"
          :deleteInvitation="deleteInvitation"
          @deletListFirend="deletListFirend"
        />
      </div>
    </div>
    <div class="w-full flex justify-start">
      <div v-if="!deleteConfirmation" class="w-full mt-6 flex justify-between">
        <AddIcon
          v-if="petoverviewTab == 1"
          :width="60"
          :height="60"
          @click.native="showAddcontactModal"
          class="cursor-pointer"
        />
        <TrashIcon
          class="md:hidden"
          bgColor="#0AC0CB"
          iconColor="#fff"
          :width="60"
          :height="60"
          @click.native="deleteConfirmation = true"
        />
        <ModalsConfirm :show="showAddcontact" @close="closeShowAddcontact">
          <div slot="actionQuestion" class="relative">
            <div
              class="absolute -top-6 -right-6 text-2xl font-proximaSemibold cursor-pointer"
              @click="closeShowAddcontact"
            >
              ✖
            </div>
            <img
              class="m-auto"
              src="@/assets/images/emailphone-icon.png"
              alt="emailphone-icon"
            />
            <h1 class="mt-4 text-2xl">Add New Contact</h1>
            <FormError :errors="errorForm" />
          </div>
          <div
            slot="actionText"
            class="mb-6 flex flex-col items-center justify-center text-sm font-proximaLight"
          >
            <input
              v-model="contactName"
              type="text"
              class="mt-2 w-9/12 px-4 py-3 border border-primary rounded-md"
              placeholder="Name or nickname"
            />
          </div>
          <div
            slot="actionText"
            class="mb-6 flex flex-col items-center justify-center text-sm font-proximaLight"
          >
            <input
              v-model="contactPhoneNumber"
              type="text"
              class="mt-2 w-9/12 px-4 py-3 border border-primary rounded-md"
              placeholder="Phone number with country code e.g. +49 123456789"
            />
          </div>
          <div
            slot="actionText"
            class="mb-6 flex flex-col items-center justify-center text-sm font-proximaLight"
          >
            <input
              v-model="contactEmailAddress"
              type="text"
              class="mt-2 w-9/12 px-4 py-3 border border-primary rounded-md"
              placeholder="Email address"
            />
          </div>
          <div slot="leftBtn" class="mb-6 mx-auto w-10/12 relative">
            <span class="font-proximaLight text-sm"
              >Add as emergency contact?</span
            >
          </div>
          <div slot="rightBtn" class="mb-6 mx-auto w-10/12 relative">
            <RadioOnoffBorder
              :val="syncCalVal"
              :checked="emergencyContact"
              @inputVal="emergencyContactVal"
            />
          </div>
          <div slot="leftBtn" class="mb-6 mx-auto w-10/12 relative">
            <span class="font-proximaLight text-sm"
              >Invite to join perPETual?</span
            >
          </div>
          <div slot="rightBtn" class="mb-6 mx-auto w-10/12 relative">
            <RadioOnoffBorder
              :val="syncCalVal"
              :checked="joinPerpetual"
              @inputVal="joinPerpetualVal"
            />
          </div>
          <div slot="leftBtn" class="mx-auto w-10/12 relative">
            <BorderedButton
              class="mx-auto"
              marginT="mt-2"
              title="Cancel"
              paddingX="px-4"
              width="w-full"
              @click.native="showAddcontact = false"
            />
          </div>
          <div slot="rightBtn" class="mx-auto w-10/12 relative">
            <SubmitButton
              class="mx-auto"
              marginT="mt-2"
              title="Confirm"
              background="bg-success"
              paddingX="px-4"
              width="w-full"
              @click.native="submitConnectRequest"
            />
          </div>
        </ModalsConfirm>
        <ModalsConnectFriend
          :show="connectRequestModals"
          :contactName="contactName.trim()"
          :contactEmailAddress="contactEmailAddress.toLowerCase().trim()"
          :contactphoneNo="contactPhoneNumber.trim()"
          :emergencyContact="emergencyContact"
          :joinPerpetual="joinPerpetual"
          @close="closeContactRequestModals"
        >
          <template #header>
            <h3>custom header</h3>
          </template>
        </ModalsConnectFriend>
      </div>
    </div>

    <div
      class="w-6/12 flex justify-center"
      v-if="petoverviewTab == 2 && deleteInvitation"
    >
      <BorderedButton
        class="mr-1"
        :title="$t('global_cancel')"
        borderColor="border-darkGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        width="w-full md:w-10/12"
        @click.native="closeModals"
      />

      <SubmitButton
        class="ml-1"
        title="Confirm"
        background="bg-success"
        paddingX="px-8"
        width="w-full md:w-10/12"
        @click.native="deleteConfirmInvitation = true"
      />
    </div>
    <div
      class="w-full flex justify-end"
      v-if="petoverviewTab == 2 && deleteInvitation == false"
    >
      <IconButton
        borderColor="border-primary"
        class="font-proximaLight mb-4"
        textCl="text-primary"
        title="Resend Invitation(s)"
        background="bg-white"
        paddingX="px-8"
        width="w-max"
        component="ResendIcon"
        iconColor="#059AA3"
      />
      <div class="m-2"></div>
      <IconButton
        borderColor="border-orange"
        class="font-proximaLight mb-4"
        textCl="text-orange"
        title="Delete Invitation(s)"
        background="bg-white"
        paddingX="px-8"
        width="w-max"
        component="TrashIcon"
        iconColor="#F89520"
        @click.native="deleteInvitation = true"
      />

      <!--  <BorderedButton
        borderColor="border-primary"
        textCl="text-primary"
        title="Resend Invitation(s)"
        width="w-max"
        paddingX="px-8"
        marginT="mt-0"
        @click.native="submitAccept(data)"
      /> -->
      <!-- <BorderedButton
        borderColor="border-orange"
        textCl="text-orange"
        title="Delete Invitation(s)"
        width="w-max"
        paddingX="px-8"
        marginT="mt-0"
        @click.native="submitDecline(data)"
      /> -->
    </div>
    <div
      v-if="deleteConfirmation"
      class="w-full flex items-center justify-between"
    >
      <bordered-button
        title="Cancel"
        width="w-5/12"
        paddingX="px-10"
        @click.native="deleteConfirmation = false"
      />
      <submit-button
        background="bg-success"
        title="Confirm"
        width="w-5/12"
        paddingX="px-10"
        @click.native="showDeleteFriendsConfirmationModals = true"
      />
      <ModalsConfirm
        :show="showDeleteFriendsConfirmationModals"
        @close="closeDeleteFriendsConfirmationModals"
      >
        <div slot="actionQuestion" class="relative">
          <div
            class="absolute -top-6 -right-6 text-2xl font-proximaSemibold cursor-pointer"
            @click="closeDeleteFriendsConfirmationModals"
          >
            ✖
          </div>
          <h1 class="text-2xl text-subheaderText">Delete friend(s)?</h1>
          <FormError :errors="errorForm" />
        </div>
        <div slot="actionText">
          <div class="text-left flex">
            <p class="text-subheaderText">
              <span class="text-red">Attention: </span>Clicking confirm will
              remove the following user(s) from your friend list:
            </p>
          </div>
          <ul class="mt-4 text-left">
            <li class="text-red" :key="item.id" v-for="item in deleteFList">
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div slot="leftBtn" class="mx-auto w-10/12 relative">
          <BorderedButton
            class="mx-auto"
            marginT="mt-2"
            title="Cancel"
            paddingX="px-4"
            width="w-full"
            @click.native="closeDeleteFriendsConfirmationModals"
          />
        </div>
        <div slot="rightBtn" class="mx-auto w-10/12 relative">
          <SubmitButton
            class="mx-auto"
            marginT="mt-2"
            title="Confirm"
            background="bg-success"
            paddingX="px-4"
            width="w-full"
            @click.native="closeDeleteFriendsConfirmationModals"
          />
        </div>
      </ModalsConfirm>
    </div>

    <ModalsConfirm :show="deleteConfirmInvitation" @close="closeModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-6 -right-6 text-2xl font-proximaSemibold cursor-pointer"
          @click="closeModals"
        >
          ✖
        </div>
        <h1 class="mt-4 text-center text-2xl">Confirm Delete</h1>
      </div>
      <div
        slot="actionText"
        class="mb-6 flex flex-col items-center justify-center text-sm font-proximaLight"
      >
        <p>
          <span class="text-red">Attention:</span> Clicking confirm will trigger
          the deletion of the selected {object type}(s). This cannot be undone.
        </p>
      </div>
      <div slot="leftBtn" class="mx-auto w-10/12 relative">
        <BorderedButton
          class="mx-auto"
          marginT="mt-2"
          title="Cancel"
          paddingX="px-4"
          width="w-full"
          @click.native="closeModals"
        />
      </div>
      <div slot="rightBtn" class="mx-auto w-10/12 relative">
        <SubmitButton
          class="mx-auto"
          marginT="mt-2"
          title="Confirm"
          background="bg-red"
          paddingX="px-4"
          width="w-full"
          @click.native="submitDeleteInvitation"
        />
      </div>
    </ModalsConfirm>
  </div>
</template>

<script>
import ContactFriends from "./ContactFriends.vue";
import ContactInvitations from "./ContactInvitations.vue";
import ModalsConnectFriend from "../modals/ModalsConnectFriend.vue";
import ContactInterestgroup from "./ContactInterestgroup.vue";
import SelectInput from "../forms/selectinput/SelectSection.vue";
import HeaderGlobal from "../header/HeaderGlobal.vue";
import AddIcon from "../icons/AddIcon.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import IconButton from "../buttons/IconButton.vue";
import RadioOnoffBorder from "../forms/RadioOnoffBorder.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import FormError from "../warning/FormError.vue";
import Loader from "../loader/Loader.vue";
import { validEmail, validPhone } from "../../methods/petMethods";
import { mapActions, mapGetters } from "vuex";

import TrashIcon from "../icons/TrashIcon.vue";

export default {
  components: {
    IconButton,
    ContactFriends,
    ContactInvitations,
    ModalsConnectFriend,
    ContactInterestgroup,
    AddIcon,
    HeaderGlobal,
    SubmitButton,
    SelectInput,
    RadioOnoffBorder,
    TrashIcon,
    BorderedButton,
    ModalsConfirm,
    FormError,
    Loader,
  },
  data() {
    return {
      showAddcontact: false,
      showAddpets: false,
      noPets: false,
      deleteConfirmation: false,
      deleteInvitation: false,
      deleteConfirmInvitation: false,
      showDeleteFriendsConfirmationModals: false,
      errorMsg: [],
      user: {},
      tokens: {},
      role: "",
      newpetRoute: "",
      contactEmailAddress: "",
      contactPhoneNumber: "",
      contactName: "",
      emergencyContact: false,
      joinPerpetual: false,
      syncCalVal: { c: false },
      deleteFList: [],
    };
  },
  computed: {
    ...mapGetters({
      contacts: "contact/getContacts",
      pendingContacts: "contact/getPendingContactsRequest",
      getError: "contact/getError",
    }),
    contactEmergencyComp() {
      return this.$store.state.contacts.fitler(
        (item) => (item.emergency_contact = true)
      );
    },
    nbContact() {
      if (this.$store.state.contacts && this.$store.state.pendingContacts)
        return (
          Object.keys(this.$store.state.contacts).length +
          Object.keys(this.$store.state.pendingContacts).length
        );
      else return 0;
    },
    petoverviewTab() {
      return this.$store.state.petoverviewTab;
    },
    connectRequestModals() {
      return this.$store.state.addNonContactRequestState;
    },
    errorForm() {
      return this.$store.state.errorForm;
    },
    showLoaderComp() {
      return this.$store.state.showLoaderState;
    },
  },
  created() {
    this.$store.commit("errorForm", "");

    this.emergencyContact = false;
    this.joinPerpetual = false;
    const userEmail = localStorage.getItem("email");
    if (userEmail) {
      this.getContacts(userEmail);
      this.getPendingContactsRequest(userEmail);
    }
  },
  beforeDestroy() {
    // clear the store
    this.emergencyContact = false;
    this.joinPerpetual = false;
    this.$store.commit("errorForm", "");
  },
  methods: {
    ...mapActions({
      getContacts: "contact/getContacts",
      getPendingContactsRequest: "contact/getPendingContactsRequest",
      addnewcontact: "contact/addnewcontact",
      deleteContact: "contact/deleteContact",
    }),
    deletListFirend(item) {
      this.deleteFList = item;
      console.log("5555", item);
    },
    closeDeleteFriendsConfirmationModals() {
      this.deleteFList.map((item) => {
        this.deleteContact(item);
      });
      this.deleteConfirmation = false;
      this.showDeleteFriendsConfirmationModals = false;
    },
    closeModals() {
      this.deleteConfirmInvitation = false;
      this.deleteInvitation = false;
    },
    closeModalDeleteInvitation() {
      this.deleteConfirmInvitation = false;
      this.deleteInvitation = false;
    },
    emergencyContactVal(val, check) {
      this.emergencyContact = !this.emergencyContact;
      console.log(val, this.emergencyContact);
    },

    joinPerpetualVal(val, check) {
      this.joinPerpetual = !this.joinPerpetual;
      console.log(val, this.joinPerpetual);
    },

    validate() {
      const errors = {};

      if (this.contactName.trim() === "") {
        this.$store.commit("errorForm", "Name or nickname is required");
        errors.errorForm = "Name or nickname is required";
      }

      if (
        this.contactPhoneNumber.trim() === "" &&
        this.contactEmailAddress.trim() === ""
      ) {
        this.$store.commit(
          "errorForm",
          "Email address or Phone number is required"
        );
        errors.errorForm = "Email address or Phone number is required";
      }

      if (this.emergencyContact) {
        if (this.contactPhoneNumber.trim() === "") {
          this.$store.commit("errorForm", "Phone number is required");
          errors.errorForm = "Phone number is required";
        }
      }
      if (this.joinPerpetual) {
        if (this.contactEmailAddress.trim() === "") {
          this.$store.commit("errorForm", "Email address is required");
          errors.errorForm = "Email address is required";
        }
      }
      if (this.contactEmailAddress.trim() !== "") {
        if (!validEmail(this.contactEmailAddress)) {
          this.$store.commit("errorForm", "Valid Email address required.");
          errors.errorForm = "Valid Email address required.";
        }
      }
      if (this.contactPhoneNumber.trim() !== "") {
        if (!validPhone(this.contactPhoneNumber)) {
          this.$store.commit("errorForm", "Valid Phone number required.");
          errors.errorForm = "Valid Phone number required";
        }
      }

      return Object.keys(errors).length === 0 ? null : errors;
    },

    submitConnectRequest() {
      const bodyData = {
        userEmailAddress: localStorage.getItem("email"), //this.owner.emailAddress,
        contactEmailAddress: this.contactEmailAddress.toLowerCase().trim(),
        contactphoneNo: this.contactPhoneNumber.trim(),
        name_label: this.contactName.trim(),
        emergency_contact: this.emergencyContact,
        emergency_status: this.emergencyContact ? 1 : 0,
        emergency_show_on_profile: false,
        referral: this.joinPerpetual,
      };
      console.log(bodyData);

      this.$store.commit("errorForm", "");

      const errors = this.validate();

      if (errors) return;
      this.addnewcontact(bodyData, 0);
      this.showAddcontact = false;
    },

    openContactRequestModals() {
      this.$store.commit("addNonContactRequestModals", true);
    },

    closeShowAddcontact() {
      this.$store.commit("errorForm", "");
      this.showAddcontact = false;
    },

    closeContactRequestModals() {
      this.$store.commit("errorForm", "");
      this.note = "";
      this.contactEmailAddress = "";
      this.contactPhoneNumber = "";
      this.contactName = "";
      this.showAddcontact = false;
      this.$store.commit("addNonContactRequestModals", false);
    },
    showAddcontactModal() {
      this.$store.commit("errorForm", "");
      this.contactName = "";
      this.contactEmailAddress = "";
      this.contactPhoneNumber = "";
      this.showAddcontact = !this.showAddcontact;
    },
  },
};
</script>

<style lang="scss" scoped>
.selectpets:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid white;
  left: -8px;
  top: 40px;
}
</style>
